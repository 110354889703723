import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import cx from 'classnames';

import { Card, CardBody } from 'reactstrap';
import { DropdownList } from 'react-widgets';

import ProjectFilter from 'common/ProjectFilter/ProjectFilter';
import TeamFilter from 'common/TeamFilter/TeamFilter';
import Datepicker from 'components/Datepicker/Datepicker';
import Search from 'common/Search/Search';
import Loader from 'common/Loader';
import ResourceRequestTable from './ResourceRequestTable';
import Pagination from 'components/Pagination/Pagination';
import ResourceRequestFooter from './ResourceRequestFooter';
import CreateRRmodal from 'common/Modals/CreateRRmodal/CreateRRmodal';

import { getResourceRequests } from 'store/resourceRequests/resourceRequests.thunk';
import { AppState } from 'store/configureStore';

import { useQuery } from 'hooks/queryHook';
import { RequestParams } from 'utils/mapParams';
import { Role } from 'constants/roles';
import { BILLING_SOURCES, DATE_PICKER, RESOURCE_REQUEST_STATUSES } from 'constants/common';
import { plus } from '../../constants/icons';

import styles from '../tableStyles.module.scss';

const ResourceRequests = () => {
  const dispatch = useDispatch();

  const { resourceRequests, totals, pages, totalCount, loading } = useSelector(
    (state: AppState) => state.resourceRequestsReducer
  );
  const { role } = useSelector((state: AppState) => state.account?.user);

  const {
    page,
    pageSize,
    fromDate,
    toDate,
    team,
    search,
    projectKey,
    status,
    billingSource,
    setPage,
    setPageSize,
    setDateRange,
    setTeamValue,
    setSearch,
    setProjectKeyData,
    setStatus,
    setBillingSourceValue,
  } = useQuery();

  const [searchData, setSearchData] = useState(search ? search : '');
  const [openCreateRRmodal, setOpenCreateRRmodal] = useState<boolean>(false);

  const statusValue = RESOURCE_REQUEST_STATUSES?.find(
    (item: string) => item.toLowerCase().split(' ').join('-') === status
  );
  const billingSourceValue = BILLING_SOURCES?.find(
    (item: string) => item.toLowerCase().split(' ').join('-') === billingSource
  );

  const getData = useCallback(() => {
    if (!fromDate || !toDate) {
      setDateRange(
        format(startOfMonth(new Date()), DATE_PICKER.dateFormatToPayload),
        format(endOfMonth(new Date()), DATE_PICKER.dateFormatToPayload)
      );
    } else {
      const params: Partial<RequestParams> = {
        page: +page,
        size: +pageSize,
        fromDate: fromDate,
        toDate: toDate,
        expand: 'workforce',
        orderBy: 'workforce',
      };

      if (search) {
        params.search = search;
      }
      if (projectKey) {
        params.projectKey = projectKey;
      }
      if (team) {
        params.team = +team;
      }
      if (status) {
        params.status = status;
      }
      if (billingSource) {
        params.billingSource = billingSource;
      }
      dispatch(getResourceRequests({ params }));
    }
  }, [dispatch, fromDate, toDate, team, pageSize, page, search, projectKey, billingSource, status]);

  useEffect(() => {
    getData();
  }, [dispatch, getData]);

  const canCreateRR = useMemo(() => role === Role.vp || role === Role.pm || role === Role.delivery, [role]);

  const onDateChange = (date: Date) => {
    setDateRange(
      format(startOfMonth(date), DATE_PICKER.dateFormatToPayload),
      format(endOfMonth(date), DATE_PICKER.dateFormatToPayload),
      searchData
    );
  };

  const onStatusChange = (value: string) => {
    setStatus(value === 'All' ? '' : value.toLowerCase().split(' ').join('-'), searchData);
  };

  const onBillingSourceChange = (value: string) => {
    setBillingSourceValue(value === 'All' ? '' : value.toLowerCase().split(' ').join('-'), searchData);
  };

  const setCurrentPageHandle = (value: number) => {
    if (value !== +page) {
      setPage(value, searchData);
    }
  };

  const setCountPerPageHandler = (value: number) => {
    setPageSize(value, searchData);
  };

  const onSearchChange = (event: React.FormEvent) => {
    setSearch(searchData.trim());
    event.preventDefault();
  };

  return (
    <>
      {canCreateRR && (
        <div className={styles.buttonWrap}>
          <button className={cx(styles.button, styles.topButton)} onClick={() => setOpenCreateRRmodal(true)}>
            {plus} Create RR
          </button>
        </div>
      )}

      <Card className="main-card mb-3">
        <CardBody>
          <div className="bp-header">
            <div className="filters-block">
              <Search
                searchValue={searchData}
                setSearchValue={setSearchData}
                updateSearchValue={onSearchChange}
                label="Workforce"
              />

              <ProjectFilter setFilterValue={setProjectKeyData} searchValue={searchData} />

              <TeamFilter team={team} setTeam={setTeamValue} planning={true} searchData={searchData} />

              <div className="dropdown-filter">
                <div className="label-wrapper">Date</div>
                <Datepicker
                  selected={fromDate ? new Date(fromDate) : startOfMonth(new Date())}
                  dateFormat={DATE_PICKER.dateFormatMonth}
                  showMonthYearPicker
                  onChange={(date: Date) => onDateChange(date)}
                />
              </div>

              <div className="dropdown-filter">
                <div className="label-wrapper">Status</div>
                <DropdownList
                  value={statusValue}
                  data={RESOURCE_REQUEST_STATUSES}
                  textField="title"
                  placeholder="All"
                  filter="contains"
                  onChange={onStatusChange}
                />
              </div>

              <div className="dropdown-filter">
                <div className="label-wrapper">Billing Source</div>
                <DropdownList
                  value={billingSourceValue}
                  data={BILLING_SOURCES}
                  textField="title"
                  placeholder="All"
                  onChange={onBillingSourceChange}
                />
              </div>
            </div>
          </div>

          {resourceRequests?.length ? (
            <>
              <div className={cx(styles.tableWrapper, 'mb-3')} style={{ overflow: 'unset' }}>
                <ResourceRequestTable data={resourceRequests || []} currentPage={+page} perPage={+pageSize} />
                <div className={styles.marginBottomTable}>
                  <Pagination
                    pages={pages}
                    perPage={+pageSize}
                    totalCount={totalCount}
                    setCurrentPage={setCurrentPageHandle}
                    currentPage={+page}
                    setCountPerPage={setCountPerPageHandler}
                    count={0}
                  />
                </div>
              </div>
              <ResourceRequestFooter totals={totals} />
            </>
          ) : loading ? (
            <Loader />
          ) : (
            'Resource Requests are empty'
          )}
        </CardBody>
      </Card>

      {openCreateRRmodal && (
        <CreateRRmodal
          onClose={() => setOpenCreateRRmodal(false)}
          callback={() => getData()}
          setOpenModal={setOpenCreateRRmodal}
        />
      )}
    </>
  );
};

export default ResourceRequests;
